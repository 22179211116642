<template>
	<b-modal id="edit-asset-type" size="lg" title="Edit Asset Type" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-3">
								<b-col lg="8" md="8" sm="12">
									<b-form-group label="Owner" label-for="Owner">
										<b-form-input name="Owner" type="text" v-model="form.origin" maxlength="100" readonly />
									</b-form-group>
								</b-col>
								<b-col lg="8" md="8" sm="12">
									<b-form-group label="Category" label-for="Category">
										<b-form-input name="Category" type="text" v-model="form.type" maxlength="100" readonly />
									</b-form-group>
								</b-col>
								<b-col lg="8" md="8" sm="12">
									<b-form-group label="Name" label-for="name">
										<b-form-input name="Name" type="text" v-model="form.name"
											v-validate="getValidationParam(true, assetTypeNameRegex)"
											maxlength="50"
											placeholder="Name"/>
										<span v-show="errors.has('Name')" class="help-block">
											{{ errors.first('Name') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="8" md="8" sm="12">
									<b-form-group label="Description" label-for="description" description>
										<b-form-textarea name="Description" type="text" v-model="form.description"
											maxlength="200" :rows="3" placeholder="Description"
											v-validate="getValidationParam(true, remarksRegex)" />
										<span v-show="errors.has('Description')" class="help-block">
											{{ errors.first('Description') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Inventory Details">
							<AssetTypeInventoryDetails :form="form" :uomOptions="uomOptions" />
						</b-tab>

						<b-tab title="Conditions">
							<AssetTypeConditions :form="form" />
						</b-tab>

						<b-tab title="Rental" v-if="isBillingModuleEnabled">
							<b-row class="my-2" v-if="isBillingModuleEnabled">
								<b-col lg="8" md="8" sm="12">
									<b-form-group label="Is Rental?" description="Determine if this asset type is for rental or not">
										<b-form-radio-group name="isRental" v-model="form.isRental" :options="yesNoOptions" 
										v-validate="{
											required: isBillingModuleEnabled
										}"/>
										<span v-show="errors.has('isRental')" class="help-block">
											{{ 'This field is required' }}
										</span>
									</b-form-group>

									<b-card v-if="isBillingModuleEnabled && form.isRental">
										<b-row>
											<b-col sm="12">
												<b-form-group label="Rent Frenquency" label-for="Rent Frenquency">
													<b-form-select id="rent-frequency" name="Rent Frenquency"
														v-model="form.rentFrequency" v-validate="{ required: isBillingModuleEnabled }"
														:options="rentFrequencyOptions"
														:reduce="(frenquency) => frenquency.value" />
													<span v-show="errors.has('Rent Frenquency')" class="help-block">
														{{ errors.first('Rent Frenquency') }}
													</span>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col sm="12">
												<b-form-group label="Rent Rate (PHP)" label-for="Rent Rate"
													description="VAT Exclusive Rate">
													<b-form-input id="rent-rate" name="Rent Rate" type="number" step="0.01"
														v-model="form.rentRate"
														v-validate="{ required: isBillingModuleEnabled, decimal: true, min_value: 0 }"
														placeholder="0.00" />
													<span v-show="errors.has('Rent Rate')" class="help-block">
														{{ errors.first('Rent Rate') }}
													</span>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Subscription">
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Status" description="For usability status of the asset type">
										<b-form-radio-group name="isActive" v-model="form.isActive" :options="statusOptions" />
										<span v-show="errors.has('isActive')" class="help-block">
											{{ 'This field is required' }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="my-2" v-show="isSuperAdmin || isManager">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Is Public?" description="For visibility of asset type to all companies">
										<b-form-radio-group name="isPublic" v-model="form.isPublic" :options="yesNoOptions" v-validate="'required'"/>
										<span v-show="errors.has('isPublic')" class="help-block">
											{{ 'This field is required' }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Components
import AssetTypeInventoryDetails from './AssetTypeInventoryDetails';
import AssetTypeConditions from './AssetTypeConditions';

// Utils
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import api from '@/api/assetTypeApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'edit-asset-type',
	components: {
		AssetTypeInventoryDetails,
		AssetTypeConditions,
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true
		},
		allAssetTypesObj: {
			type: Object,
			required: true
		},
		uomOptions: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			form: { ...AssetTypeUtil.getDefaultAssetTypeObj() },

			rentFrequencyOptions: { ...config.rentFrequencyTypes },
			statusOptions: { ...config.statusOptions },
			yesNoOptions: [
				{ text: 'YES', value: true },
				{ text: 'NO', value: false },
			],

			selCompany: { ...config.companyDefaultValue },

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isManager: this.$store.getters.isManager,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		'form.rentRate': function () {
			if (this.form.rentRate) {
				this.form.rentRate = parseFloat(this.form.rentRate);
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
		assetTypeNameRegex() {
			return config.assetTypeNameRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		isBillingModuleEnabled() {
			if (this.selCompany && this.selCompany.id) {
				const companyObj = this.allCompaniesObj[this.selCompany.id] ? this.allCompaniesObj[this.selCompany.id] : {};
				let permissions = companyObj.permissions ? companyObj.permissions : {};
				return permissions.billing ? permissions.billing : false;
			}
			return false;
		}
	},
	methods: {
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},

		handleFormData() {
			this.form.origin = this.selCompany.name;
			this.form.originId = this.selCompany.id;
			this.form.brand = this.selCompany.brand;

			// Removes excess whitespace
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			this.handleFormData();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			} else if (AssetTypeUtil.exceedMaximumPublic(this.allCompaniesObj[this.selCompany.id], this.allAssetTypesObj, this.form)) {
				this.$toaster.warning(`You have exceeded the allowed number (${config.maxInQuery}) of public asset types for this company.`);
				this.isLoading = false;
				return;
			}

			isValid = this.validateAssetType();
			if (!isValid) {
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},

		validateAssetType() {
			if (this.isBillingModuleEnabled) {
				if (this.form.rentRate < 0) {
					this.$toaster.warning('Rent rate must be greater than 0.00 PHP.');
					return false;
				} else {
					this.form.rentRate = parseFloat(this.form.rentRate);
					this.form.rentRate = parseFloat(this.form.rentRate.toFixed(2));
				}
			}

			for (const condition of this.form.conditions) {
				if (!condition.description || !/^([A-Za-z ])*$/.test(condition.description)) {
					this.$toaster.warning(`Condition '${condition.condition}' has invalid description. It must contain only letters (A-Z, a-z).`);
					return false;
				} else if (condition.description.length > 25) {
					this.$toaster.warning("Invalid condition description. It must be at most 25 characters long.");
					return false;
				}
			}

			return true;
		},

		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				let { data } = await api.saveAssetType({
					currUserId: this.loggedUser.id,
					assetType: this.form,
				});

				if (data.isSuccess) {
					this.$toaster.success(`Asset Type "${this.name}" was updated successfully.`);
					EventBus.$emit('onCloseSaveAssetType', { assetType: data.assetType });
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error updating asset type "${this.name}". Please try again.`);
				}

			} catch (error) {
				this.$toaster.error(`Error updating asset type "${this.name}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...this.$store.getters.currAssetType };
			this.form = AssetTypeUtil.cleanupFields(this.form);

			// update other fields
			this.form.rentFrequency = this.form.rentFrequency || 'Per Day';
			this.form.rentCurrency = this.form.rentCurrency || 'PHP';
			this.form.rentRate = this.form.rentRate || 0;
			this.form.isPublic = this.form.isPublic || false;

			if (!this.form.inventoryDetails) {
				this.$set(this.form, 'inventoryDetails', AssetTypeUtil.getDefaultInventoryDetails());
			}

			if (!this.form.conditions) {
				this.$set(this.form, 'conditions', AssetTypeUtil.getDefaultConditions());
			}

			// init company
			this.selCompany = {
				id: this.form.originId,
				name: this.form.origin,
				brand: this.form.brand,
			};

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style scoped>
.lbl-passed {
	font-weight: bold !important;
	color: #28a745 !important;
}

.lbl-failed {
	font-weight: bold !important;
	color: #dc3545 !important;
}

.add-button {
	width: 100px;
}
</style>